const datetimeFormat = "YYYY-MM-DD HH:mm:ss";
const dateFormat = "YYYY-MM-DD";
const UTCMode = true;

const countryStatsChartLimit = 10;

const rateCardsPrecision = {
  CPC: { format: "n3", decimals: 3 },
  CPM: { format: "n2", decimals: 2 },
  CPA: { format: "n3", decimals: 3 },
  CPS: { format: "n1", decimals: 1 },
};

const si = ["k", "M", "B", "T", "P", "E"];

export const sseEvents = {
  SHOW_HELP: "help:show",
  SHOW_FATAL_ERROR: "fatal_error:show",
};

export const defaultCurrency = "USD";
export const mainCurrencies = ["USD", "PLN", "EUR", "GBP", "BRL", "RUB", "CZK", "UAH"];

export default {
  rateCardsPrecision,
  datetimeFormat,
  dateFormat,
  UTCMode,
  countryStatsChartLimit,
  si,
  allPinnedToCampaignCreativesFilter: "ALL",
  sseEvents,
};
